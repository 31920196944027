// subjob constants
export const allJobs = 'all-jobs';
export const assigned = 'assigned';
export const ongoing = 'ongoing';
export const completed = 'completed';
export const partial = 'partial';
export const pendingResolve = 'pending-resolve';
export const archived = 'archived';

// user list constants
export const user = 'user';
export const client = 'client';
export const admin = 'admin';

// user task resolving constants
export const pending = 'pending';
export const unassigned = 'unassigned';
export const approved = 'approved';
export const rejected = 'rejected';
export const expired = 'expired';
export const unclaimed = 'unclaimed';
export const pipelineOngoing = 'pipelineOngoing';

export const editAccuracyCompletion = 'accuracy / % completed';
export const editCoins = 'coins';

// content flag
export const adult = 'adult';

// file upload types
export const srtStep3 = 'srt';
export const audioStep3 = 'audio';
export const videoStep3 = 'video';
export const sampleImages = 'sample-images';
export const configInputStep3 = 'config-input';
export const imageInputStep3 = 'image-input';

// toast constants
export const statusCardSuccess = 'success';
export const statusCardError = 'error';

// referral constants
export const referralReward = 'referralReward';
export const allReferrals = 'all';
export const pendingReferrals = 'pending';
export const completedReferrals = 'completed';

// job methods
export const JobMethod = {
  // Image
  ImageSwipeTwoWay: 'image_swipe_two_way',
  ImageRating: 'image_rating',
  ImageChoice: 'image_choice',
  ImageMultiSelect: 'image_multi_select',
  ImageTranscription: 'image_transcription',
  ImageBoundingPolygon: 'image_bounding_polygon',
  ImageBoundingBox: 'image_bounding_box',
  ImageCollectionBasic: 'image_collection_basic',

  // Text
  TextSwipeTwoWay: 'text_swipe_two_way',
  TextTagging: 'text_tagging',
  TextChoice: 'text_choice',
  TextMultiSelect: 'text_multi_select',
  TextTranscription: 'text_transcription',

  // Video
  VideoSwipeTwoWay: 'video_swipe_two_way',
  VideoChoice: 'video_choice',

  // Audio
  AudioTranscriptionCleansing: 'audio_transcription_cleansing',
  AudioCollection: 'audio_collection',

  // QC
  ImageChoiceQcImageSwipeTwoWay: 'image_choice_qc_image_swipe_two_way',
  ImageBoundingBoxQcImageSwipeTwoWay: 'image_bounding_box_qc_image_swipe_two_way',
  ImageBoundingPolygonQcImageSwipeTwoWay: 'image_bounding_polygon_qc_image_swipe_two_way',
  ImageCollectionBasicQcImageSwipeTwoWay: 'image_collection_basic_qc_image_swipe_two_way',
  TextChoiceQcTextSwipeTwoWay: 'text_choice_qc_text_swipe_two_way',
  AudioTranscriptionCleansingQcAudioSwipeTwoWay: 'audio_transcription_cleansing_qc_audio_swipe_two_way',
  AudioCollectionQcAudioSwipeTwoWay: 'audio_collection_qc_audio_swipe_two_way',
};

export const inputTypedJobMethods = [JobMethod.AudioCollection, JobMethod.ImageCollectionBasic];

export const aiAssistanceSupportedJobMethod = [JobMethod.ImageBoundingPolygon];

export const intervals = {
  fetchGenerationStatusData: 10 * 1000, // 10 seconds
};

export const CSVDownloadOptions = {
  CSV: 'csv',
  CSVWithBom: 'csv-bom',
};

// log constants
export const logTypes = {
  announcement: 'announcement',
  update: 'update',
  maintenance: 'maintenance',
  message: 'message',
  others: 'others',
};

// Feature flags
export const featureFlagNames = {
  disableCashRedemption: 'disable-cash-redemption',
  disableAdminTaskGuidelines: 'disable-admin-task_guidelines',
  disableAdminJobParametersInessential: 'disable-admin-job_parameters_inessential',
  disableAdminBadges: 'disable-admin-badges',
  disableAdminInessentials: 'disable-admin-inessentials',
};

export const featureFlagValue = {
  on: 'on',
  off: 'off',
};

export const adminItemReviewSupportedJobMethods = [
  JobMethod.ImageBoundingBox,
  JobMethod.ImageCollectionBasic,
  JobMethod.AudioCollection,
];

export const consensusSupportedJobMethods = [
  JobMethod.ImageSwipeTwoWay,
  JobMethod.ImageChoice,
  JobMethod.ImageMultiSelect,
  JobMethod.TextSwipeTwoWay,
  JobMethod.TextChoice,
  JobMethod.TextMultiSelect,
  JobMethod.VideoChoice,
];

export const HttpStatus = {
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  PROCESSING: 102,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  AMBIGUOUS: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  REQUESTED_RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  I_AM_A_TEAPOT: 418,
  UNPROCESSABLE_ENTITY: 422,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
};

export const publicJobBoardSupportedJobMethods = [JobMethod.AudioCollection];
