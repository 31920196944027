import { buildQueryParams } from 'utils';
import * as endpoints from './endpoints';

export default class TrainingJobService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async getList(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);

      const endpoint = endpoints.TRAINING_JOB_LIST_ADMIN;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getTrainingModuleEnrollment(parameters) {
    try {
      const { payload } = parameters;
      const queryParameters = buildQueryParams(payload);
      const url = `${endpoints.TRAINING_MODULE_ENROLLMENT}?${queryParameters}`;

      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async deleteTrainingModuleEnrollment(parameters) {
    try {
      const { payload } = parameters;
      const queryParameters = buildQueryParams(payload);
      const url = `${endpoints.TRAINING_MODULE_ENROLLMENT}?${queryParameters}`;

      const { data, status } = await this.HTTP.delete(url);

      return {
        ...data,
        status,
      };
    } catch (error) {
      throw error.response.data;
    }
  }
}
