import {
  CHANGE_JOB_LIST_PAGE,
  CHANGE_JOB_LIST_PER_PAGE,
  CHANGE_JOB_LIST_TAB,
  CHANGE_JOB_LIST_FILTER,
  CHANGE_JOB_LIST_SEARCH,
  CHANGE_USER_LIST_PAGE,
  CHANGE_USER_LIST_PER_PAGE,
  CHANGE_USER_LIST_TAB,
  CHANGE_USER_LIST_SEARCH,
  CHANGE_COIN_TAGGER_LIST_PAGE,
  CHANGE_COIN_TAGGER_LIST_PER_PAGE,
  CHANGE_COIN_TAGGER_LIST_SEARCH,
  CHANGE_BADGES_LIST_PAGE,
  CHANGE_BADGES_LIST_PER_PAGE,
  CHANGE_BADGES_LIST_SEARCH,
  CHANGE_SUB_JOBS_TAGGER_LIST_PER_PAGE,
  CHANGE_CASH_PRIZE_REQUEST_LIST_PAGE,
  CHANGE_CASH_PRIZE_REQUEST_LIST_PER_PAGE,
  CHANGE_CASH_PRIZE_REQUEST_LIST_SEARCH,
  CHANGE_CASH_PRIZE_REQUEST_LIST_STATUS,
  CHANGE_USER_TASK_LIST_PAGE,
  CHANGE_USER_TASK_LIST_TAB,
  CHANGE_USER_TASK_LIST_PER_PAGE,
  CHANGE_INVITE_CODES_LIST_PAGE,
  CHANGE_INVITE_CODES_LIST_PER_PAGE,
  CHANGE_INVITE_CODES_LIST_SEARCH,
  CHANGE_REFERRAL_RECORD_LIST_PAGE,
  CHANGE_REFERRAL_RECORD_LIST_PER_PAGE,
  CHANGE_REFERRAL_RECORD_LIST_SEARCH,
  CHANGE_REFERRAL_RECORD_LIST_TAB,
  CHANGE_REFERRAL_RECORD_LIST_IS_CAMPAIGNS_ONLY,
  CHANGE_DELETION_REQUEST_LIST_PAGE,
  CHANGE_DELETION_REQUEST_LIST_PER_PAGE,
  CHANGE_QA_MECHANISM_DECISION_LIST_PAGE,
  CHANGE_QA_MECHANISM_DECISION_LIST_PER_PAGE,
  CHANGE_QA_MECHANISM_DECISION_LIST_SEARCH,
  CHANGE_QA_MECHANISM_DECISION_LIST_PAGINATE,
  CHANGE_LOG_LIST_PAGE,
  CHANGE_LOG_LIST_PER_PAGE,
  CHANGE_LOG_LIST_TYPE,
} from './types';

// Job List
const changeJobListPage = (pageObject) => {
  return {
    type: CHANGE_JOB_LIST_PAGE,
    payload: pageObject,
  };
};

const changeJobListTab = (tab) => {
  return {
    type: CHANGE_JOB_LIST_TAB,
    payload: tab,
  };
};

const changeJobListPerPage = (perPage) => {
  return {
    type: CHANGE_JOB_LIST_PER_PAGE,
    payload: perPage,
  };
};

const changeJobListFilter = (filter) => {
  return {
    type: CHANGE_JOB_LIST_FILTER,
    payload: filter,
  };
};

const changeJobListSearch = (search) => {
  return {
    type: CHANGE_JOB_LIST_SEARCH,
    payload: search,
  };
};

// User List
const changeUserListPage = (pageObject) => {
  return {
    type: CHANGE_USER_LIST_PAGE,
    payload: pageObject,
  };
};

const changeUserListPerPage = (perPage) => {
  return {
    type: CHANGE_USER_LIST_PER_PAGE,
    payload: perPage,
  };
};

const changeUserListTab = (tab) => {
  return {
    type: CHANGE_USER_LIST_TAB,
    payload: tab,
  };
};

const changeUserListSearch = (search) => {
  return {
    type: CHANGE_USER_LIST_SEARCH,
    payload: search,
  };
};

// User Task List
const changeUserTaskListPage = (pageObject) => {
  return {
    type: CHANGE_USER_TASK_LIST_PAGE,
    payload: pageObject,
  };
};

const changeUserTaskListTab = (tab) => {
  return {
    type: CHANGE_USER_TASK_LIST_TAB,
    payload: tab,
  };
};

const changeUserTaskListPerPage = (perPage) => {
  return {
    type: CHANGE_USER_TASK_LIST_PER_PAGE,
    payload: perPage,
  };
};

const changeCoinTaggerListPage = (page) => {
  return {
    type: CHANGE_COIN_TAGGER_LIST_PAGE,
    payload: page,
  };
};

const changeCoinTaggerListPerPage = (perPage) => {
  return {
    type: CHANGE_COIN_TAGGER_LIST_PER_PAGE,
    payload: perPage,
  };
};

const changeCoinTaggerListSearch = (search) => {
  return {
    type: CHANGE_COIN_TAGGER_LIST_SEARCH,
    payload: search,
  };
};

const changeBadgesListPage = (page) => {
  return {
    type: CHANGE_BADGES_LIST_PAGE,
    payload: page,
  };
};

const changeBadgesListPerPage = (perPage) => {
  return {
    type: CHANGE_BADGES_LIST_PER_PAGE,
    payload: perPage,
  };
};

const changeBadgesListSearch = (search) => {
  return {
    type: CHANGE_BADGES_LIST_SEARCH,
    payload: search,
  };
};

const changeCashPrizeListPage = (page) => {
  return {
    type: CHANGE_CASH_PRIZE_REQUEST_LIST_PAGE,
    payload: page,
  };
};

const changeCashPrizeListPerPage = (perPage) => {
  return {
    type: CHANGE_CASH_PRIZE_REQUEST_LIST_PER_PAGE,
    payload: perPage,
  };
};

const changeCashPrizeListSearch = (search) => {
  return {
    type: CHANGE_CASH_PRIZE_REQUEST_LIST_SEARCH,
    payload: search,
  };
};

const changeCashPrizeListStatus = (status) => {
  return {
    type: CHANGE_CASH_PRIZE_REQUEST_LIST_STATUS,
    payload: status,
  };
};

// Sub Jobs Tagger List
const changeSubJobsTaggerListPerPage = (perPage) => {
  return {
    type: CHANGE_SUB_JOBS_TAGGER_LIST_PER_PAGE,
    payload: perPage,
  };
};

const changeInviteCodesListPage = (page) => {
  return {
    type: CHANGE_INVITE_CODES_LIST_PAGE,
    payload: page,
  };
};

const changeInviteCodesListPerPage = (perPage) => {
  return {
    type: CHANGE_INVITE_CODES_LIST_PER_PAGE,
    payload: perPage,
  };
};

const changeInviteCodesListSearch = (search) => {
  return {
    type: CHANGE_INVITE_CODES_LIST_SEARCH,
    payload: search,
  };
};

const changeReferralsListPage = (pageObject) => {
  return {
    type: CHANGE_REFERRAL_RECORD_LIST_PAGE,
    payload: pageObject,
  };
};

const changeReferralsListPerPage = (perPage) => {
  return {
    type: CHANGE_REFERRAL_RECORD_LIST_PER_PAGE,
    payload: perPage,
  };
};

const changeReferralsListSearch = (search) => {
  return {
    type: CHANGE_REFERRAL_RECORD_LIST_SEARCH,
    payload: search,
  };
};

const changeReferralsListTab = (tab) => {
  return {
    type: CHANGE_REFERRAL_RECORD_LIST_TAB,
    payload: tab,
  };
};

const changeReferralsListIsCampaignsOnly = (isCampaignsOnly) => {
  return {
    type: CHANGE_REFERRAL_RECORD_LIST_IS_CAMPAIGNS_ONLY,
    payload: isCampaignsOnly,
  };
};

// Deletion Request List
const changeDeletionRequestListPage = (page) => {
  return {
    type: CHANGE_DELETION_REQUEST_LIST_PAGE,
    payload: page,
  };
};

const changeDeletionRequestListPerPage = (perPage) => {
  return {
    type: CHANGE_DELETION_REQUEST_LIST_PER_PAGE,
    payload: perPage,
  };
};

const changeQaMechanismDecisionListPage = (page) => {
  return {
    type: CHANGE_QA_MECHANISM_DECISION_LIST_PAGE,
    payload: page,
  };
};

const changeQaMechanismDecisionListPerPage = (perPage) => {
  return {
    type: CHANGE_QA_MECHANISM_DECISION_LIST_PER_PAGE,
    payload: perPage,
  };
};

const changeQaMechanismDecisionListSearch = (search) => {
  return {
    type: CHANGE_QA_MECHANISM_DECISION_LIST_SEARCH,
    payload: search,
  };
};

const changeQaMechanismDecisionListPaginate = (paginate) => {
  return {
    type: CHANGE_QA_MECHANISM_DECISION_LIST_PAGINATE,
    payload: paginate,
  };
};

const changeLogListPage = (page) => {
  return {
    type: CHANGE_LOG_LIST_PAGE,
    payload: page,
  };
};

const changeLogListPerPage = (perPage) => {
  return {
    type: CHANGE_LOG_LIST_PER_PAGE,
    payload: perPage,
  };
};

const changeLogListType = (type) => {
  return {
    type: CHANGE_LOG_LIST_TYPE,
    payload: type,
  };
};

const JobListPaginationActions = {
  changeJobListPage,
  changeJobListTab,
  changeJobListPerPage,
  changeJobListFilter,
  changeJobListSearch,
};

const UserListPaginationActions = {
  changeUserListPage,
  changeUserListPerPage,
  changeUserListTab,
  changeUserListSearch,
};

const UserTaskListPaginationActions = {
  changeUserTaskListPage,
  changeUserTaskListTab,
  changeUserTaskListPerPage,
};

const QaDecisionListPaginationActions = {
  changeQaMechanismDecisionListPage,
  changeQaMechanismDecisionListPerPage,
  changeQaMechanismDecisionListPaginate,
  changeQaMechanismDecisionListSearch,
};

const CoinTaggerListPaginationActions = {
  changeCoinTaggerListPage,
  changeCoinTaggerListPerPage,
  changeCoinTaggerListSearch,
};

const BadgesListPaginationActions = {
  changeBadgesListPage,
  changeBadgesListPerPage,
  changeBadgesListSearch,
};

const CashPrizeRequestListPaginationActions = {
  changeCashPrizeListPage,
  changeCashPrizeListPerPage,
  changeCashPrizeListSearch,
  changeCashPrizeListStatus,
};

const SubJobsTaggerListPaginationActions = {
  changeSubJobsTaggerListPerPage,
};

const InviteCodesListPaginationActions = {
  changeInviteCodesListPage,
  changeInviteCodesListPerPage,
  changeInviteCodesListSearch,
};

const ReferralRecordListPaginationActions = {
  changeReferralsListPage,
  changeReferralsListPerPage,
  changeReferralsListSearch,
  changeReferralsListTab,
  changeReferralsListIsCampaignsOnly,
};

const DeletionRequestPaginationActions = {
  changeDeletionRequestListPage,
  changeDeletionRequestListPerPage,
};

const LogPaginationActions = {
  changeLogListPage,
  changeLogListPerPage,
  changeLogListType,
};

export {
  JobListPaginationActions,
  UserListPaginationActions,
  UserTaskListPaginationActions,
  CoinTaggerListPaginationActions,
  SubJobsTaggerListPaginationActions,
  BadgesListPaginationActions,
  CashPrizeRequestListPaginationActions,
  InviteCodesListPaginationActions,
  ReferralRecordListPaginationActions,
  DeletionRequestPaginationActions,
  QaDecisionListPaginationActions,
  LogPaginationActions,
};
