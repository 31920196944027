import {
  sampleImages,
  srtStep3,
  audioStep3,
  configInputStep3,
  imageInputStep3,
  JobMethod,
  videoStep3,
} from 'common/constants/global';
import { buildQueryParams, buildQueryParam } from 'utils';
import * as endpoints from './endpoints';

export default class JobService {
  constructor(httpService) {
    this.HTTP = httpService;
  }

  async getList(parameters) {
    const { type, payload, customPayload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      let customQueryParameters = [];
      for (const key in customPayload) {
        if (key === 'method') {
          customQueryParameters = customQueryParameters.concat(
            customPayload[key].map((item) => `${buildQueryParam('method', item)}`),
          );
        } else if (key === 'search_filter' && customPayload[key].filter_value) {
          customQueryParameters.push(buildQueryParam(customPayload[key].filter_type, customPayload[key].filter_value));
        }
      }
      const endpoint = type === 'admin' ? endpoints.JOB_LIST_ADMIN : endpoints.JOB_LIST_CLIENT;
      let url = `${endpoint}?${queryParameters}`;
      if (customQueryParameters.length !== 0) {
        url += `&${customQueryParameters.join('&')}`;
      }
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getJobTaskItems(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.JOB_TASK_ITEMS}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getDetail(parameters) {
    const { type, payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = type === 'admin' ? endpoints.JOB_DETAIL_ADMIN : endpoints.JOB_DETAIL_CLIENT;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getQueryOptions(parameters) {
    const { type } = parameters;

    try {
      const endpoint =
        type === 'admin' ? endpoints.JOB_LIST_QUERY_OPTIONS_ADMIN : endpoints.JOB_LIST_QUERY_OPTIONS_CLIENT;
      const url = `${endpoint}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getDownloadFormatOptions(parameters) {
    const { type, payload } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = type === 'admin' ? endpoints.JOB_DOWNLOAD_FORMAT_ADMIN : endpoints.JOB_DOWNLOAD_FORMAT_CLIENT;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getDownloadDataFormatOptions(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_DOWNLOAD_DATA_FORMAT;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getListTagger(parameters) {
    const { type, payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = type === 'admin' ? endpoints.JOB_LIST_TAGGER_ADMIN : endpoints.JOB_LIST_TAGGER_CLIENT;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async setTagger(parameters) {
    const { type, payload, payloadBody } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = type === 'admin' ? endpoints.SET_JOB_TAGGER_ADMIN : endpoints.SET_JOB_TAGGER_CLIENT;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.put(url, payloadBody);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async download(parameters) {
    const { type, payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = type === 'admin' ? endpoints.JOB_DOWNLOAD_ADMIN : endpoints.JOB_DOWNLOAD_CLIENT;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url, {}, 'arraybuffer');

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async downloadWithUsername(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = endpoints.JOB_DOWNLOAD_WITH_USERNAME_ADMIN;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url, {}, 'arraybuffer');

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async downloadUrl(parameters) {
    const { type, payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = type === 'admin' ? endpoints.JOB_DOWNLOAD_ADMIN : endpoints.JOB_DOWNLOAD_CLIENT;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async prepareDownload(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = endpoints.JOB_PREPARE_DOWNLOAD;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.post(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async prepareDownloadState(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = endpoints.JOB_PREPARE_DOWNLOAD_STATE;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getListType() {
    try {
      const endpoint = endpoints.JOB_TYPES_CLIENT;

      const { data } = await this.HTTP.get(endpoint);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async create(parameters) {
    const { step } = parameters;

    switch (step) {
      case 1:
        return this.createStepOne(parameters);
      case 2:
        return this.createStepTwo(parameters);
      case 3:
      case sampleImages:
      case audioStep3:
      case videoStep3:
      case srtStep3:
      case configInputStep3:
      case imageInputStep3:
        return this.uploadJobFiles(parameters);
      case 4:
        return this.createStepFour(parameters);
      case 5:
        return this.createStepFive(parameters);
    }
  }

  async createQC(parameters) {
    const { payload, payloadBody } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_CREATE_STEP_ONE_PIPELINE_CLIENT;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.post(url, payloadBody);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getSampleImages(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_FILES_SAMPLE_IMAGES;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async deleteSampleImages(parameters) {
    const { payload, payloadBody } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_FILES_SAMPLE_IMAGES;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.delete(url, payloadBody);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async updateSampleImageOrder(parameters) {
    const { payload, payloadBody } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_EDIT_SAMPLE_IMAGES;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.put(url, payloadBody);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async updateDetails(parameters) {
    const { payload, payloadBody } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_UPDATE_DETAILS;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.put(url, payloadBody);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async createStepOne(parameters) {
    const { payload, payloadBody } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_CREATE_STEP_ONE_CLIENT;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.post(url, payloadBody);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async createStepTwo(parameters) {
    const { payload, payloadBody } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_CREATE_STEP_TWO_CLIENT;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.put(url, payloadBody);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async uploadJobFiles(parameters) {
    const { payload, payloadBody, config, step } = parameters;
    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      let endpoint;
      switch (step) {
        case sampleImages:
          endpoint = endpoints.JOB_FILES_SAMPLE_IMAGES;
          break;
        case audioStep3:
          endpoint = endpoints.JOB_CREATE_STEP_THREE_AUDIO_SOUNDTRACK;
          break;
        case videoStep3:
          endpoint = endpoints.JOB_CREATE_STEP_THREE_VIDEOS;
          break;
        case srtStep3:
          endpoint = endpoints.JOB_CREATE_STEP_THREE_AUDIO_TRANSCRIPTION;
          break;
        case configInputStep3:
          endpoint = endpoints.JOB_CREATE_STEP_THREE_CONFIG_INPUT;
          break;
        case imageInputStep3:
          endpoint = endpoints.JOB_CREATE_STEP_THREE_INPUT;
          break;
        case 3:
        default:
          endpoint = endpoints.JOB_CREATE_STEP_THREE_CLIENT;
      }

      const url = `${endpoint}?${queryParameters}`;
      const formData = new FormData();
      for (const file of payloadBody) {
        formData.append('files[]', file);
      }

      const { data } = await this.HTTP.put(url, formData, config);

      return data;
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        throw error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        throw error.request;
      } else {
        // Something happened in setting up the request that triggered an Error
        throw error.message;
      }
    }
  }

  async getJobFiles(parameters) {
    const { payload, typeJob, fileType } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      let endpoint;
      switch (typeJob) {
        case 'audio_transcription_cleansing':
          if (fileType === audioStep3) endpoint = endpoints.JOB_CREATE_STEP_THREE_AUDIO_SOUNDTRACK;
          if (fileType === srtStep3) endpoint = endpoints.JOB_CREATE_STEP_THREE_AUDIO_TRANSCRIPTION;
          break;
        case JobMethod.VideoChoice:
          if (fileType === videoStep3) endpoint = endpoints.JOB_CREATE_STEP_THREE_VIDEOS;
          break;
        default:
          break;
      }

      if (endpoint) {
        const url = `${endpoint}?${queryParameters}`;

        const { data } = await this.HTTP.get(url);

        return data;
      }
    } catch (error) {
      throw error.response.data;
    }
  }

  async deleteJobFiles(parameters) {
    const { payload, payloadBody, typeJob, fileType } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      let endpoint;
      switch (typeJob) {
        case 'audio_transcription_cleansing':
          if (fileType === audioStep3) endpoint = endpoints.JOB_CREATE_STEP_THREE_AUDIO_SOUNDTRACK;
          if (fileType === srtStep3) endpoint = endpoints.JOB_CREATE_STEP_THREE_AUDIO_TRANSCRIPTION;
          break;
        default:
          break;
      }

      if (endpoint) {
        const url = `${endpoint}?${queryParameters}`;

        const { data } = await this.HTTP.delete(url, payloadBody);

        return data;
      }
    } catch (error) {
      throw error.response.data;
    }
  }

  async processAudioFiles(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_CREATE_STEP_THREE_AUDIO_PROCESS;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.post(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async processVideoFiles(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_CREATE_STEP_THREE_VIDEO_PROCESS;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.post(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async processInputAgnosticFiles(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_CREATE_STEP_THREE_INPUT_AGNOSTIC_PROCESS;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.post(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getInputAgnosticFilesProcessStatus(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_CREATE_STEP_THREE_INPUT_AGNOSTIC_PROCESS_STATUS;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async createEmptyFiles(parameters) {
    const { payload, payloadBody } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_CREATE_STEP_THREE_INPUT_AGNOSTIC_CREATE_EMPTY_FILES;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.put(url, payloadBody);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async createStepFour(parameters) {
    const { payload, payloadBody, typeJob } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      let endpoint;
      switch (typeJob) {
        case 'image_rating':
          endpoint = endpoints.JOB_CREATE_STEP_FOUR_RANGE_CLIENT;
          break;
        case 'image_bounding_box_qc_image_swipe_two_way':
        case 'image_bounding_polygon_qc_image_swipe_two_way':
        case 'image_choice_qc_image_swipe_two_way':
        case 'text_choice_qc_text_swipe_two_way':
        case 'audio_transcription_cleansing_qc_audio_swipe_two_way':
        case 'audio_collection_qc_audio_swipe_two_way':
        case 'image_collection_basic_qc_image_swipe_two_way':
          endpoint = endpoints.JOB_CREATE_STEP_FOUR_DECISION_CLIENT;
          break;
        case 'image_transcription':
        case 'text_transcription':
          endpoint = endpoints.JOB_CREATE_STEP_FOUR_TEXT_TAGS_PER_LABEL_CLIENT;
          break;
        case JobMethod.AudioTranscriptionCleansing:
        case JobMethod.AudioCollection:
        case JobMethod.ImageCollectionBasic:
          endpoint = endpoints.JOB_CREATE_STEP_FOUR_BASE_CLIENT;
          break;
        default:
          endpoint = endpoints.JOB_CREATE_STEP_FOUR_LABEL_CLIENT;
      }

      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.put(url, payloadBody);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async createStepFive(parameters) {
    const { payload, payloadBody } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_CREATE_STEP_FIVE_CLIENT;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.put(url, payloadBody);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async copyItems(parameters) {
    const { payload, payloadBody } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_COPY_ITEMS_CLIENT;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.post(url, payloadBody);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async jobBoardSubmit(parameters, payload) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.JOB_BOARD_ADMIN}?${queryParameters}`;
      const { data } = await this.HTTP.post(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getJobBoardDetails(parameters) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.JOB_BOARD_ADMIN}?${queryParameters}`;

      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async addJobBoardItems(payload) {
    try {
      const url = `${endpoints.JOB_BOARD_ADMIN_ADD_ITEMS}`;

      const { data } = await this.HTTP.put(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async removeJobBoardItems(payload) {
    try {
      const url = `${endpoints.JOB_BOARD_ADMIN_REMOVE_ITEMS}`;

      const { data } = await this.HTTP.put(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async updateJobBoardParameters(parameters, payload) {
    try {
      const queryParameters = buildQueryParams(parameters);
      const url = `${endpoints.JOB_BOARD_ADMIN}?${queryParameters}`;

      const { data } = await this.HTTP.put(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async setContentFlag(parameters) {
    const { payload, payloadBody } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_CONTENT_FLAG;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.put(url, payloadBody);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getRejectionReasons(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const url = `${endpoints.JOB_REJECTION_REASONS}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async setRejectionReasons(parameters) {
    const { payload, payloadBody } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const url = `${endpoints.JOB_REJECTION_REASONS}?${queryParameters}`;
      const { data } = await this.HTTP.put(url, payloadBody);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async disableRejectionReasons(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const url = `${endpoints.JOB_DISABLE_REJECTION_REASONS}?${queryParameters}`;
      const { data } = await this.HTTP.put(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async archiveJob(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_ARCHIVE;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.put(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async unarchiveJob(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = payload ? buildQueryParams(payload) : '';
      const endpoint = endpoints.JOB_UNARCHIVE;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.put(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getCurrentTagged(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const url = `${endpoints.JOB_CURRENT_TAGGED_ADMIN}?${queryParameters}`;
      const { data } = await this.HTTP.get(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async addPasses(parameters) {
    const { payload } = parameters;

    try {
      const queryParameters = buildQueryParams(payload);
      const endpoint = endpoints.JOB_ADD_PASSES;
      const url = `${endpoint}?${queryParameters}`;

      const { data } = await this.HTTP.put(url);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async configurePublicJobBoard(request) {
    const { parameters, payload } = request;

    try {
      const queryParameters = parameters ? buildQueryParams(parameters) : '';
      const endpoint = endpoints.JOB_BOARD_ADMIN_PUBLIC_JOB_BOARD;
      const url = `${endpoint}?${queryParameters}`;
      const { data } = await this.HTTP.put(url, payload);

      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
}
