import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';

const StyledTabs = styled(({ border, ...other }) => <Tabs {...other} />)({
  '& .MuiTabs-scroller > span': {
    height: '3px',
    backgroundColor: '#f6ad55',
  },
  borderBottom: (props) => (props.border ? '1px solid #ededed' : ''),
  display: 'inline-flex',
});

const StyledTab = styled(Tab)(({ showIndicator }) => ({
  '& .MuiTab-wrapper': {
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'capitalize',
    '&:hover': {
      opacity: 0.4,
    },
    ...(showIndicator && {
      '&::after': {
        content: '""',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: '#CB0000',
        marginLeft: '8px',
      },
    }),
  },
  minWidth: 120,
}));

const TcTabs = memo((props) => {
  const { children, activeName, onTabClick, full, border, containerLabelClassname = '', className } = props;
  const Children = React.Children.toArray(children);

  const [activeTab, setActiveTab] = useState(activeName);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const RenderTabs = () => {
    return React.Children.map(Children, (item, itemIndex) => {
      const { label, name, showIndicator, disabled } = item.props;

      return (
        <StyledTab
          key={itemIndex}
          value={name}
          label={label}
          onClick={() => {
            onTabClick(name);
          }}
          disableRipple
          showIndicator={showIndicator}
          disabled={disabled}
        />
      );
    });
  };

  const RenderTabPanes = () => {
    return React.Children.map(Children, (item, itemIndex) => {
      const { name } = item.props;

      return (
        <div key={itemIndex} hidden={name !== activeTab}>
          {item}
        </div>
      );
    });
  };

  return (
    <div className={className}>
      <StyledTabs
        value={activeName}
        onChange={handleTabChange}
        variant={full ? 'fullWidth' : 'standard'}
        border={border}
        className={containerLabelClassname}
      >
        {RenderTabs()}
      </StyledTabs>
      {RenderTabPanes()}
    </div>
  );
});

TcTabs.propTypes = {
  children: PropTypes.node.isRequired,
  activeName: PropTypes.string.isRequired,
  onTabClick: PropTypes.func,
  full: PropTypes.bool,
  border: PropTypes.bool,
  containerLabelClassname: PropTypes.string,
  className: PropTypes.string,
};

TcTabs.defaultProps = {
  full: false,
  border: true,
  containerLabelClassname: '',
  className: '',
};

const Pane = memo(({ children }) => {
  return <div>{children}</div>;
});

Pane.propTypes = {
  children: PropTypes.node,
};

TcTabs.Pane = Pane;

export default TcTabs;
