import PropTypes from 'prop-types';
import React from 'react';

const TcSectionClass = `
  tw-rounded-lg
  tw-bg-white
  tw-pb-4
  tw-px-8
  tw-mt-6
`;

const TcSection = (props) => {
  const { children, className, useDefaultClass } = props;

  const combinedClassName = `${useDefaultClass ? TcSectionClass : ''} ${className}`;

  return <div className={combinedClassName}>{children}</div>;
};

TcSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  useDefaultClass: PropTypes.bool,
};

TcSection.defaultProps = {
  className: '',
  useDefaultClass: true,
};

export default TcSection;
