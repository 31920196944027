import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { arrowDownIcon, arrowUpDownIcon, arrowUpIcon } from 'assets';
import { TcIcon } from 'common/components';

const StyledTableCell = styled(TableCell)({
  borderStyle: 'solid',
  borderColor: '#DFDFDF',
  borderTopWidth: 1,
  borderBottomWidth: 1,
  color: '#B8B8B8',
});

const StyledSortableTableCell = styled(TableCell)({
  borderStyle: 'solid',
  borderColor: '#DFDFDF',
  borderTopWidth: 1,
  borderBottomWidth: 1,
  color: '#B8B8B8',
  cursor: 'pointer',
});

const TcTable = ({
  headers,
  rows,
  sortableHeaderStates,
  rowHoverEffect,
  onRowClick,
  sortCallBack,
  stickyHeader,
  onTitleClick,
  isOnTitleClickActive,
  headerBackGroundColor,
  headersStyle,
  cellStyle,
  headerAlign,
  cellAlign,
  cellTextWrap,
}) => {
  const handleSort = (headerKey) => {
    if (sortableHeaderStates[headerKey] === null) return;

    if (sortableHeaderStates[headerKey] === '') sortCallBack(headerKey, 'asc');
    else if (sortableHeaderStates[headerKey] === 'asc') sortCallBack(headerKey, 'desc');
    else if (sortableHeaderStates[headerKey] === 'desc') sortCallBack('', '');
  };

  const isEmptyRow = (row) => {
    return Object.keys(row).length === 0 && row.constructor === Object;
  };

  const RenderHeaders = () => {
    const result = [];

    for (const header of headers) {
      if (isEmptyRow(header) || header?.hidden) continue;
      if (header.sort) {
        result.push(
          <StyledSortableTableCell
            key={header.key}
            style={{ width: `${header.width}%` }}
            align={header.align ?? headerAlign}
            onClick={() => handleSort(header.key)}
          >
            <RenderSortIcon headerName={header.label} headerKey={header.key} />
          </StyledSortableTableCell>,
        );
      } else {
        result.push(
          <StyledTableCell
            key={header.key}
            style={{
              width: `${header.width}%`,
              backgroundColor: headerBackGroundColor,
              ...(header.headerStyle ? header.headerStyle : headersStyle),
            }}
            align={header.align ?? headerAlign}
          >
            {header.label}
          </StyledTableCell>,
        );
      }
    }

    return result;
  };

  const RenderRows = () => {
    return rows.map((row, index) => {
      if (isEmptyRow(row)) return null;
      return (
        <TableRow key={index} hover={rowHoverEffect} onClick={onRowClick ? () => onRowClick(index) : undefined}>
          {RenderCells(row, index)}
        </TableRow>
      );
    });
  };

  const RenderCells = (row, rowDataIndex) => {
    const result = [];

    for (const [index, cell] of row.entries()) {
      if (isEmptyRow(cell) || headers[index]?.hidden) continue;

      if (isOnTitleClickActive && headers[index].key === 'title') {
        result.push(
          <TableCell key={index} align={cell.align ?? cellAlign}>
            <a
              onClick={(event) => {
                event.stopPropagation();
                onTitleClick(rowDataIndex);
              }}
              className="tw-cursor-pointer tw-underline"
            >
              {cell.label}
            </a>
            {cell?.secondary && <span className="tw-text-gray-800">{cell.secondary}</span>}
          </TableCell>,
        );
      } else {
        const styles = { ...(cell?.truncate && { maxWidth: 100 }) };

        const className = `tw-whitespace-${cell?.textWrap || cellTextWrap} ${cell?.truncate && 'tw-truncate'} 
          ${cell?.textTransform && `tw-${cell.textTransform}`}`;

        result.push(
          <TableCell
            style={{ ...styles, ...(cell?.style ? cell.style : cellStyle) }}
            className={className}
            key={index}
            align={cell.align ?? cellAlign}
          >
            {cell.label}
            {cell?.secondary && <span className="tw-text-gray-800"> {cell.secondary}</span>}
          </TableCell>,
        );
      }
    }

    return result;
  };

  const RenderSortIcon = ({ headerName, headerKey }) => {
    if (sortableHeaderStates[headerKey] === '') {
      return (
        <>
          {headerName}
          <TcIcon src={arrowUpDownIcon} alt="arrowUpDown" width="10px" className="tw-ml-2" />
        </>
      );
    }

    if (sortableHeaderStates[headerKey] === 'asc') {
      return (
        <>
          {headerName}
          <TcIcon src={arrowUpIcon} alt="arrowDown" width="10px" className="tw-ml-2" />
        </>
      );
    }

    if (sortableHeaderStates[headerKey] === 'desc') {
      return (
        <>
          {headerName}
          <TcIcon src={arrowDownIcon} alt="arrowDown" width="10px" className="tw-ml-2" />
        </>
      );
    }
  };

  return (
    <Table stickyHeader={stickyHeader}>
      <TableHead>
        <TableRow>{RenderHeaders()}</TableRow>
      </TableHead>
      <TableBody>{RenderRows()}</TableBody>
    </Table>
  );
};

TcTable.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  sortableHeaderStates: PropTypes.object,
  rowHoverEffect: PropTypes.bool,
  onRowClick: PropTypes.func,
  sortCallBack: PropTypes.func,
  stickyHeader: PropTypes.bool,
  onTitleClick: PropTypes.func,
  isOnTitleClickActive: PropTypes.bool,
  headerBackGroundColor: PropTypes.string,
  headersStyle: PropTypes.object,
  cellStyle: PropTypes.object,
  headerAlign: PropTypes.string,
  cellAlign: PropTypes.string,
  cellTextWrap: PropTypes.string,
};

TcTable.defaultProps = {
  rowHoverEffect: false,
  stickyHeader: false,
  isOnTitleClickActive: false,
  headerBackGroundColor: '',
  headersStyle: {},
  cellStyle: {},
  headerAlign: 'left',
  cellAlign: 'left',
  cellTextWrap: 'normal',
};

export default TcTable;
